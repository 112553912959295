import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';


import Layout from '../components/layout';
import ProjectTeaser from '../components/ProjectTeaser';
import Icon from '../components/Icon';
import SEO from '../components/seo';

const ProjectsPage = ({ data, location }) => {
  const projects = data.allMarkdownRemark.edges
    .filter(item => item.node.frontmatter.type === 'project')
    .map(item => item.node);

  return (
    <Layout location={location}>
      <SEO title="Projects" />

      <div className="container-fluid">
        <div className="ml-md-4">
          <Fade><h1>Projects</h1></Fade>

          <Fade bottom distance="30px" cascade>
            <div className="row mt-3">
              {projects.map((project, i) => (
                <div className="col-6 col-md-4 mb-2" key={i}>
                  <ProjectTeaser project={project} />
                </div>
              ))}
            </div>
          </Fade>
        </div>

        <div className="mt-4 mb-2">
          <div className="separator">
            <Icon icon="drakar" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

ProjectsPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default ProjectsPage;

export const query = graphql`
  query AllProjects {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          frontmatter {
            path
            title
            subtitle
            type
            thumbnail
          }
        }
      }
    }
  }
`;
